import { Helmet } from 'react-helmet';
/* eslint-disable jsx-quotes */
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Container,
  IconButton,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import OrderListToolbar from '../components/order/OrderListToolbar';

const orders = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    order: {
      name: 'Pedido 1',
    },
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    order: {
      name: 'Pedido 2',
    },
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    order: {
      name: 'Pedido 3',
    },
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    order: {
      name: 'Pedido 4',
    },
    customer: {
      name: 'Anje Keizer'
    },
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    order: {
      name: 'Pedido 5',
    },
    customer: {
      name: 'Clarke Gillebert'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    order: {
      name: 'Pedido 6',
    },
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

const OrderList = (props) => (
  <>
    <Helmet>
      <title>Lista de Pedido | Helfer Comunicação</title>
    </Helmet>
    <Container maxWidth={false}>
      <OrderListToolbar />
      <Box sx={{ pt: 3 }}>
        <Card {...props}>
          <CardHeader title="Pedidos" />
          <Divider />
          <PerfectScrollbar>
            <Box sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Código
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Nome do Pedido
                    </TableCell>
                    <TableCell>
                      Locutor
                    </TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Data
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell align='center'>
                      Editar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow
                      hover
                      key={order.id}
                    >
                      <TableCell>
                        {order.ref}
                      </TableCell>
                      <TableCell>
                        {order.order.name}
                      </TableCell>
                      <TableCell>
                        {order.customer.name}
                      </TableCell>
                      <TableCell>
                        {moment(order.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Chip
                          color="primary"
                          label={order.status}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton color="successColor">
                          <EditIcon />
                        </IconButton>
                        <IconButton color="warningColor">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
            >
              Ver todos
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  </>
);

export default OrderList;

/* eslint-disable jsx-quotes */
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Container,
  IconButton,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import OrderListToolbar from '../../components/users/UsersListToolbar';
import { useApi } from '../../hooks/useApi';

const UsersList = (props) => {
  const api = useApi();
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    async function loadUsers() {
      const resp = await api.userList(token);
      setUsers(resp.data);
    }
    loadUsers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Lista de usuários | Helfer Comunicação</title>
      </Helmet>
      <Container maxWidth={false}>
        <OrderListToolbar />
        <Box sx={{ pt: 3 }}>
          <Card {...props}>
            <CardHeader title="Lista de usuários" />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Código
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Nome do Usuário
                      </TableCell>
                      <TableCell>
                        Email
                      </TableCell>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Data de criação
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Tipo de usuário
                      </TableCell>
                      <TableCell align='center'>
                        Editar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow
                        hover
                        key={user.id}
                      >
                        <TableCell>
                          {user.id}
                        </TableCell>
                        <TableCell>
                          {user.first_name}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          {moment(user.created_at).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {user.roles?.map((role) => (
                            <Chip
                              key={role.id}
                              color="primary"
                              label={role.name}
                              size="small"
                              style={{ marginLeft: '4px' }}
                            />
                          ))}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton color="successColor">
                            <EditIcon />
                          </IconButton>
                          <IconButton color="warningColor">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                Ver todos
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default UsersList;

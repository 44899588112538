/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable eol-last */
/* eslint-disable */
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Container,
  IconButton,
  SvgIcon,
  DialogActions,
} from '@material-ui/core';
import {
  Plus as AddIcon
} from 'react-feather';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogCredcard from 'src/components/Dialog/DialogCredcard';
import SalePlan from './SalePlan';

const myPlans = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    order: {
      name: 'Pedido 1',
    },
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    order: {
      name: 'Pedido 2',
    },
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    order: {
      name: 'Pedido 3',
    },
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  }
];

const MyPlans = (props) => {
  const [open, setOpen] = useState(false);


  return (
    <>
      <Helmet>
        <title>Meus Planos | Helfer Comunicação</title>
      </Helmet>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            sx={{
              marginLeft: '1rem',
            }}
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <SvgIcon
              fontSize="small"
              color="white"
            >
              <AddIcon />
            </SvgIcon>
            Plano
          </Button>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Card {...props}>
            <CardHeader title="Meus Planos" />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Código
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Nome do Pedido
                      </TableCell>
                      <TableCell>
                        Locutor
                      </TableCell>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Data
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell align='center'>
                        Editar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myPlans.map((plans) => (
                      <TableRow
                        hover
                        key={plans.id}
                      >
                        <TableCell>
                          {plans.ref}
                        </TableCell>
                        <TableCell>
                          {plans.order.name}
                        </TableCell>
                        <TableCell>
                          {plans.customer.name}
                        </TableCell>
                        <TableCell>
                          {moment(plans.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={plans.status}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton color="successColor">
                            <EditIcon />
                          </IconButton>
                          <IconButton color="warningColor">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                Ver todos
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
      <DialogCredcard title={"Adquirir Plano"} openDialog={open} setOpenDialog={setOpen}>
        <SalePlan />
      </DialogCredcard>
    </>
  )
};

export default MyPlans;
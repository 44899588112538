/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable */
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/Auth/AuthContext';

const AccountProfile = (props) => {
  const { user } = useContext(AuthContext);
  const userData = user?.data;
  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={userData?.image_url}
            sx={{
              height: 100,
              width: 100
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {`${userData?.first_name} ${userData?.last_name}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {/* {user.roles[1].name} */}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('HH:mm')}H`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
        >
          Mudar Foto
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountProfile;

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable jsx-quotes */
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Paper,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  TextareaAutosize
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  paper: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  textHelper: {
    fontSize: 12,
    color: '#9e9e9f',
  }
}));

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const MakeOrder = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    firstName: 'Katarina',
    lastName: 'Smith',
    email: 'demo@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
      {...props}
    >
      <Paper className={classes.paper}>
        <CardHeader
          subheader="Cadastro de pedidos"
          title="Novo Pedido"
        />
        <Divider />
        <CardContent className={classes.root}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecione seu plano"
                name="plan"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              <Typography>
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
            >
              <TextField
                fullWidth
                helperText="Titulo para sua gravação"
                label="Titulo da gravação"
                name="title"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
            >
              <TextField
                fullWidth
                helperText="ex: 30 segundos (30s)"
                label="Duração da gravação"
                name="duration"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecione o Locutor"
                name="announcer"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Selecione o estilo da gravação"
                name="style"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
            </Grid>
            <Grid
              item
              md={10}
              xs={12}
            >
              <Typography className={classes.textHelper}>
                O campo guia, é um local para inserir uma gravação de áudio para auxiliar o locutor na hora da gravação como por ex: nomes regionalistas, supostos nomes em língua estrangeira ou estilo de gravação, que podem gerar dúvidas ao locutor na hora de gravar.
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Observação ao locutor"
                multiline
                rows={3}
                defaultValue="Digite uma observação para o locutor..."
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Observação ao Produtor"
                multiline
                rows={3}
                defaultValue="Digite uma observação para o Produtor..."
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Digite seu texto"
                multiline
                rows={5}
                defaultValue="Digite aqui a mensagem que deseja gravar..."
                variant="outlined"
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography className={classes.textHelper}>
                (A cada 642 caracteres será descontado 1(uma) gravação)
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2
          }}
        >
          <Button color="secondary" variant="contained" href='/app/order'>Voltar</Button>
          <Button
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </Box>
      </Paper>
    </form>
  );
};

export default MakeOrder;

/* eslint-disable */
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  SvgIcon
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  Grid as GridIcon
} from 'react-feather';
import NavItem from './NavItem';

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const avatar = localStorage.getItem('avatar');
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = async () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          <NavItem href="/app/dashboard" title="Dashboard" icon={BarChartIcon} />
          <NavItem href="/app/myplans" title="Meus planos" icon={GridIcon} />
          <NavItem href="/app/order" title="Pedidos" icon={UsersIcon} />
          <NavItem href="/app/settings" title="Minha Conta" icon={SettingsIcon} />
          <NavItem href="/app/#" title="Locutor" icon={SettingsIcon} />
          <NavItem href="/app/#" title="Produtor" icon={SettingsIcon} />
          <NavItem href="/app/users" title="Usuários" icon={UsersIcon} />
          <NavItem href="/app/plans" title="Planos" icon={GridIcon} />
        </List>
      </Box>
      <Box sx={{ p: 2 }} style={{ marginTop: 'auto', marginBottom: '5px', alignItems: 'start' }}>
        <List>
          <NavItem href="/app/#" title="Tornar Locutor" icon={GridIcon} />
          <NavItem href="/app/#" title="Tornar Produtor" icon={SettingsIcon} />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 250,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;

/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';

const EmailValidation = () => {
  return (
    <>
      <Helmet>
        <title>Validação de e-mail | Helfer Comunicação</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm" style={{ backgroundColor: "#fff" }}>

          <Box sx={{ mb: 3 }}>
            <Typography
              color="textPrimary"
              variant="h2"
              sx={{ mb: 3 }}
            >
              Validação de e-mail
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Token Fornecido para validação encotra-se invalido ou expirado.
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
            </Typography>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => window.location.href = "/login"}
            >
              ir para o painel
            </Button>
          </Box>
        </Container>

      </Box>
    </>
  );
}

export default EmailValidation;
/* eslint-disable */
import React from 'react'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';


const GlobalDialogs = (props) => {

  const { title, children, openDialog, setOpenDialog } = props;

  const handleClose = () => {
    setOpenDialog(false);
  };


  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  );

}

export default GlobalDialogs;
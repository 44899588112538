/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  TextField,
  Box,
  Button,
  Switch,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TableBody,
  IconButton,
  Chip,
  TablePagination,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast } from 'react-toastify';
import { useApi } from 'src/hooks/useApi';

const useStyles = makeStyles(() => ({
  formControl: {
    // margin: 4,
    width: '100%',
  },
  selectEmpty: {
    marginTop: 5,
  },
  formControl2: {
    marginTop: 15,
    width: '100%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreatePlans = (props) => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [contract, setContract] = useState(false);
  const [accumulative, setAccumulative] = useState(false);
  const [visible, setVisible] = useState(false);
  const [announceisList, setAnnounceisList] = useState([]);
  const [announcers, setAnnouncers] = useState([]);
  const [values, setValues] = useState({
    plan_category_id: "",
    name: "",
    quantity: "",
    amount: "",
    additional_information: '',
  });
  useEffect(() => {
    const getCategory = async () => {
      const resp = await api.listCategoryPlan(token);
      setCategoryList(resp.data);
      setLoading(false);
    };
    getCategory();
  }, []);

  useEffect(() => {
    const getAnnouncers = async () => {
      const resp = await api.getAnnouncers(token);
      setAnnounceisList(resp.data);
    }
    getAnnouncers();
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleChangeAnnouncers = (event) => {
    setAnnouncers(event.target.value);
  }

  const handleSubmit = async (event) => {
    if (values.plan_category_id === '' || values.name === '' || values.quantity === '' || values.amount === '' || values.additional_information === '') {
      toast.error('Erro ao criar plano, preencha todos os campos!');
    } else {

      const newData = {
        plan_category_id: values.plan_category_id,
        name: values.name,
        quantity: values.quantity,
        amount: values.amount,
        additional_information: values.additional_information,
        contract: contract,
        accumulative: accumulative,
        visible: visible,
        announcers: announcers
      }
      console.log('valor gerado', newData)
      event.preventDefault();

      try {
        const resp = await api.createPlan(token, newData);
        if (resp.data.status === 200) {
          toast.success('Plano criado com sucesso!');
        }
      } catch (error) {
        toast.error('Erro ao criar plano, preencha todos os campos!');
      }
      return resp;
    }
  };



  return (
    <>
      {!loading ? (
        <form
          autoComplete="off"
          noValidate
          {...props}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={4}
              xs={4}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category-label">Categoria</InputLabel>
                <Select
                  fullWidth
                  name='plan_category_id'
                  labelId="category-label"
                  id="plan_category_id"
                  value={values.plan_category_id || ''}
                  onChange={handleChange}
                  label="Categoria"
                >
                  <MenuItem value="">
                    <em>Selecione Categoria</em>
                  </MenuItem>
                  {loading ? (
                    <MenuItem value="">
                      <em>Carregando...</em>
                    </MenuItem>
                  ) : (
                    categoryList.map((category) => (
                      <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    )))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xs={4}
            >
              <TextField
                fullWidth
                label="Nome do Plano"
                name="name"
                onChange={handleChange}
                required
                value={values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={2}
            >
              <TextField
                fullWidth
                label="Quantidade"
                name="quantity"
                onChange={handleChange}
                required
                type={'number'}
                value={values.quantity || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              xs={2}
            >
              <TextField
                fullWidth
                label="Valor"
                name="amount"
                onChange={handleChange}
                required
                type={'number'}
                value={values.amount || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Informação Adicional"
                name="additional_information"
                onChange={handleChange}
                required
                value={values.additional_information || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              mt={3}
              ml={3}
            >
              <Grid
                item
                md={3}
                xs={3}
              >
                <Typography>
                  Contrato
                </Typography>
                <Switch
                  checked={values.contract}
                  onChange={() => setContract(!contract)}
                  color="primary"
                  name="contract"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={3}
              >
                <Typography>
                  Acumulativo
                </Typography>
                <Switch
                  checked={values.accumulative}
                  onChange={() => setAccumulative(!accumulative)}
                  color="primary"
                  name="accumulative"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={3}
              >
                <Typography>
                  Visivel
                </Typography>
                <Switch
                  checked={values.visible}
                  onChange={() => setVisible(!visible)}
                  color="primary"
                  name="visible"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormControl className={classes.formControl2}>
                  <InputLabel id="announceis-label">Anunciantes</InputLabel>
                  <Select
                    labelId="announceis-label"
                    id="mutiple-name"
                    multiple
                    name='id'
                    value={announcers || []}
                    onChange={handleChangeAnnouncers}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em>Selecione os anunciantes</em>
                    </MenuItem>
                    {announceisList.map((announcer) => (
                      <MenuItem key={announcer.id} value={announcer.id}>
                        {announcer.person.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                mt={1}
              >
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 3 }}
                  fullWidth
                  onClick={handleSubmit}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (<Typography>Carregando...</Typography>)}
      <ToastContainer />
    </>
  );
}

export default CreatePlans;

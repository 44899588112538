/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { AuthContext } from '../contexts/Auth/AuthContext';

const Register = () => {
  // eslint-disable-next-line no-unused-vars
  const auth = useContext(AuthContext);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      toast.warn('A senha deve ter no mínimo 8 caracteres');
    }

    try {
      if (email && password && name && lastName) {
        const res = await auth.signUp(name, lastName, email, password);
        if (res.message === "OK") {
          toast.success('Cadastro realizado com sucesso!');
          window.location.href = '/login';
        }
      } else {
        toast.warn('Preencha todos os campos');
      }
    } catch (err) {
      if (err === 'Unauthorized') {
        toast.warn('Essa conta já está cadastrada');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Registrar | Helfer Comunicação</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Crie sua conta
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Crie sua conta e tenha acesso ao painel
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="Nome"
              margin="normal"
              name="name"
              variant="outlined"
              value={name}
              onChange={(t) => setName(t.target.value)}
            />
            <TextField
              fullWidth
              label="Sobrenome"
              margin="normal"
              name="lastName"
              variant="outlined"
              value={lastName}
              onChange={(t) => setLastName(t.target.value)}
            />
            <TextField
              fullWidth
              label="Email Address"
              margin="normal"
              name="email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(t) => setEmail(t.target.value)}
            />
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(t) => setPassword(t.target.value)}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Criar
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Você já tem uma conta?
              {' '}
              <Link component={RouterLink} to="/login" variant="h6" underline="hover">
                Login
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
      <ToastContainer />
    </>
  );
};

export default Register;

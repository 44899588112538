/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable */
import { Helmet } from 'react-helmet';
import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from '../components/account/AccountProfile';
import AccountProfileDetails from '../components/account/AccountProfileDetails';
import AccountProfileFullDetails from '../components/account/AccountProfileFullDetails';
import { AuthContext } from 'src/contexts/Auth/AuthContext';

const SettingsView = () => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <>
      {loading ? (
        <p>Carregando</p>
      ) :
        <>
          <Helmet>
            <title>Configuração | Helfer Comunicação</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: 'background.default',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth="lg">
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <AccountProfile />
                </Grid>
                <Grid
                  item
                  lg={8}
                  md={6}
                  xs={12}
                >
                  <AccountProfileDetails />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <AccountProfileFullDetails />
                </Grid>
              </Grid>
            </Container>
          </Box>

        </>
      }
    </>
  );
};

export default SettingsView;

/* eslint-disable prefer-const */
/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Switch,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast } from 'react-toastify';
import { useApi } from '../../../hooks/useApi';
import Cards from 'react-credit-cards';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  paper: {
    width: '100%',
  },
  formControl: {
    minWidth: '100%',
  },
}));

const initialHolderInfo = {
  name: "",
  email: "",
  cpfCnpj: "",
  postalCode: "",
  address: "",
  addressNumber: "",
  addressComplement: "",
  phone: ""
};


const SalePlan = () => {
  const classes = useStyles();
  const api = useApi();
  const [holderInfo, setHolderInfo] = useState(initialHolderInfo);
  const [serviceTerms, setServiceTerms] = useState(false);
  const [plans, setPlans] = useState([]);
  const [salesPlan, setSalesPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [useExistingCustomer, setUseExistingCustomer] = useState(false);

  useEffect(() => {
    const getPlans = async () => {
      const token = localStorage.getItem('token');
      const response = await api.getPlans(token);
      setPlans(response.data);
      setLoading(false);
    };
    getPlans();
  }, []);

  function onChangeFocus(e) {
    setFocus(e.target.name);
  }

  const handlePlanSelect = (e) => {
    e.preventDefault();
    const plan = plans.find((plan) => plan.id === e.target.value);
    setSalesPlan(plan);
  };

  const handleHolderInfo = (e) => {
    const { name, value } = e.target;
    setHolderInfo({ ...holderInfo, [name]: value });
  };

  const handleOnChangeCheck = (e) => {
    setUseExistingCustomer(e.target.checked);
  };

  const data = {
    serviceTerms: serviceTerms,
    plan_id: salesPlan.id,
    billingType: "CREDIT_CARD",
    useExistingCustomer: useExistingCustomer,
    creditCardId: null,
    creditCard: {
      holderName: name,
      number: number,
      expiryMonth: expiry.split("/")[0],
      expiryYear: expiry.split("/")[1],
      ccv: cvc
    },
    creditCardHolderInfo: {
      name: holderInfo.name,
      email: holderInfo.email,
      cpfCnpj: holderInfo.cpfCnpj,
      postalCode: holderInfo.postalCode,
      addressNumber: holderInfo.addressNumber,
      addressComplement: holderInfo.addressComplement,
      phone: holderInfo.phone
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!serviceTerms) {
      toast.error('Você precisa aceitar os termos de serviço para continuar.');
      return;
    }
    if (!salesPlan.id) {
      toast.error('Você precisa escolher um plano para continuar.');
      return;
    }
    try {
      const response = await api.salesPlan(token, data);
      if (response.status === 200) {
        toast.success('Plano comprado com sucesso!');
      } else {
        toast.error('Erro ao comprar plano.');
      }
    } catch (error) {
      toast.error('Você já tem esse plano. Adquira outro plano.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
      <form onSubmit={handleSubmit}>
        <Container maxWidth="lg">
          <Grid
            container
          >
            <Grid item lg={5} md={6} xs={12} mt={5}>
              <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
              />
            </Grid>
            <Grid item lg={7} md={6} xs={12}>
              <Grid item lg={12} md={12} xs={12} mb={2}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="outlined-nossos-planos-simple">Selecione seu plano</InputLabel>
                  <Select
                    fullWidth
                    labelId="outlined-nossos-planos-simple"
                    id="demo-simple-select-outlined"
                    value={salesPlan.id || ''}
                    onChange={handlePlanSelect}
                    label="Selecione seu plano"
                  >
                    <MenuItem value=""> <em>Selecione seu Plano </em> </MenuItem>
                    {loading ? (
                      <MenuItem value="">Carregando...</MenuItem>
                    ) : (
                      plans.map((plan) => (
                        <MenuItem key={plan.id} value={plan.id}>{plan.name} - {plan.planCategory.name} - {plan.amount}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} mb={2}>
                  <TextField
                    fullWidth
                    value={number}
                    onFocus={onChangeFocus}
                    helperText="Digite o número do cartão"
                    label="Número do Cartão"
                    name="number"
                    onChange={(e) => setNumber(e.target.value)}
                    required
                    inputProps={{ maxLength: 16 }}
                    variant="outlined"
                    type={'text'}
                  />
                </Grid>
                <Grid item md={3} xs={3} mb={2}>
                  <TextField
                    fullWidth
                    value={cvc}
                    label="CVC"
                    name="cvc"
                    onChange={(e) => setCvc(e.target.value)}
                    required
                    inputProps={{ maxLength: 4 }}
                    onFocus={onChangeFocus}
                    variant="outlined"
                    type={'number'}
                    placeholder={'XXX'}
                  />
                </Grid>
                <Grid item md={3} xs={3} mb={2}>
                  <TextField
                    fullWidth
                    value={expiry}
                    label="Validade"
                    name="expiry"
                    onChange={(e) => setExpiry(e.target.value)}
                    required
                    inputProps={{ maxLength: 7 }}
                    onFocus={onChangeFocus}
                    variant="outlined"
                    type={'tel'}
                    placeholder={'MM/AAAA'}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xs={12} mb={1}>
                <TextField
                  fullWidth
                  value={name}
                  helperText="obrigatório Nome Completo"
                  label="Nome Completo"
                  name="name"
                  type={'text'}
                  onChange={(e) => setName(e.target.value)}
                  required
                  onFocus={onChangeFocus}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12} >
                <FormControlLabel
                  control={<Switch checked={useExistingCustomer} onChange={handleOnChangeCheck} color="primary" />}
                  label="O titular do cartão é o mesmo do cliente?"
                />
              </Grid>

            </Grid>

            <Grid container item xs={12} spacing={2}>
              {useExistingCustomer === false ? (
                <>
                  <Grid item lg={12} md={12} xs={12} >
                    <Typography variant="h6" color="textPrimary">
                      Dados do titular
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item lg={8} md={8} xs={8} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.name}
                      helperText="obrigatório Nome Completo"
                      label="Nome Completo"
                      name="name"
                      type={'text'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={4} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.cpf}
                      helperText="digite CPF/CNPJ sem pontos e barras"
                      label="CPF"
                      name="cpf"
                      type={'text'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={5} md={5} xs={5} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.address}
                      label="Endereço"
                      name="address"
                      type={'text'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xs={2} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.addressNumber}
                      label="Número"
                      name="addressNumber"
                      type={'number'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xs={2} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.zipCode}
                      label="CEP"
                      name="zipCode"
                      type={'tel'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xs={3} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.addressComplement}
                      label="Complemento"
                      name="addressComplement"
                      type={'text'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} mb={1}>
                    <TextField
                      fullWidth
                      value={holderInfo.email}
                      label="Email"
                      name="email"
                      type={'text'}
                      onChange={handleHolderInfo}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </>
              )
                : <></>
              }
              <Grid item lg={9} md={9} xs={9} mb={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serviceTerms}
                      onChange={() => setServiceTerms(!serviceTerms)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Confirmar que li e aceito os termos de uso e política de privacidade"
                />
              </Grid>
              <Grid item lg={3} md={3} xs={3} mb={1}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Comprar
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </form>
      <ToastContainer />
    </Box >
  );
};

export default SalePlan;

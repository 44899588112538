/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable jsx-quotes */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Container,
  IconButton,
  SvgIcon,
  DialogActions
} from '@material-ui/core';
import {
  Plus as AddIcon
} from 'react-feather';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApi } from '../../../hooks/useApi';
import CategoryPlans from './CategoryPlans';
import CreatePlans from './Plans';
import DialogCredcard from 'src/components/Dialog/DialogCredcard';

const Plans = (props) => {
  const api = useApi();
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState('');
  const [title, setTitle] = useState('');

  const ListPlans = async () => {
    const token = localStorage.getItem('token');
    const plans = await api.getPlans(token);
    setPlans(plans.data);
  };

  const handleOpenNewCategory = () => {
    setOpen(true)
    setTitle('Nova Categoria')
    setDialog('category')
  };

  const handleOpenNewPlan = () => {
    setOpen(true)
    setTitle('Novo Plano')
    setDialog('plan')
  };

  const handleClose = () => {
    setOpen(false)
    setTitle('')
    setDialog('')
  };


  useEffect(() => {
    ListPlans();
  }, []);
  return (
    <>
      <Helmet>
        <title>Cadastro de Planos | Helfer Comunicação</title>
      </Helmet>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenNewCategory}
          >
            <SvgIcon
              fontSize="small"
              color="white"
            >
              <AddIcon />
            </SvgIcon>
            Categorias
          </Button>
          <Button
            sx={{
              marginLeft: '1rem',
            }}
            color="primary"
            variant="contained"
            onClick={handleOpenNewPlan}
          >
            <SvgIcon
              fontSize="small"
              color="white"
            >
              <AddIcon />
            </SvgIcon>
            Planos
          </Button>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Card {...props}>
            <CardHeader title="Planos" />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Código
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Plano
                      </TableCell>
                      <TableCell>
                        Categoria
                      </TableCell>
                      <TableCell>
                        Valor
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell align='center'>
                        Editar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map((plans) => (
                      <TableRow
                        hover
                        key={plans.id}
                      >
                        <TableCell>
                          {plans.id}
                        </TableCell>
                        <TableCell>
                          {plans.name}
                        </TableCell>
                        <TableCell>
                          {plans.planCategory.name}
                        </TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(plans.amount)}
                        </TableCell>
                        <TableCell>
                          {plans.active === true ? (
                            <Chip
                              color="primary"
                              label="Ativo"
                              size="small"
                            />
                          ) : (
                            <Chip
                              color="secondary"
                              label="Inativo"
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton color="successColor">
                            <EditIcon />
                          </IconButton>
                          <IconButton color="warningColor">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                Ver todos
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
      {dialog === 'category' ? (
        <DialogCredcard title={"Criar/Editar Categoria"} openDialog={open} setOpenDialog={setOpen}>
          <CategoryPlans />
        </DialogCredcard>
      ) : (
        <DialogCredcard title={"Cadastrar Plano"} openDialog={open} setOpenDialog={setOpen}>
          <CreatePlans />
        </DialogCredcard>)
      }
    </>
  );
};

export default Plans;
/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable */
import { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  TextField,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from 'src/contexts/Auth/AuthContext';
import { useApi } from '../../hooks/useApi';

const initialStatePerson = {
  type: '',
  document: '',
  name: '',
  observations: '',
  site: '',
  skype: '',
  rg: '',
  birth_date: '',
  fantasy_name: '',
  state_registration: '',
  municipal_registration: '',
  creation_date: '',
}

const initialStateAddress = {
  city: {
    id: '',
    name: '',
    state_id: '',
    uf: '',
  },
  postal_code: '',
  address_name: '',
  address_number: '',
  complement: '',
  neighborhood: '',
}

const initialState = {
  active: '',
  customer_category: '',
  email: '',
}

const initialStatePhone = [{
  type_phone_id: 1,
  number: ''
},
{
  type_phone_id: 2,
  number: '',
},
]



const AccountProfileFullDetails = (props) => {
  const { user } = useContext(AuthContext);
  const userData = user?.data;
  const api = useApi();
  const [categories, setCategories] = useState([]);
  const [getUserData, setGetUserData] = useState(initialState);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [person, setPerson] = useState(initialStatePerson);
  const [address, setAddress] = useState(initialStateAddress);
  const [phones, setPhones] = useState(initialStatePhone);
  const [loading, setLoading] = useState(true);
  const [loadingCities, setLoadingCities] = useState(true);

  useEffect(() => {
    const getCategory = async () => {
      const token = localStorage.getItem('token');
      const category = await api.customerCategory(token);
      setCategories(category.data);
    };
    getCategory();
  }, []);

  useEffect(() => {
    const getAdicionalInfo = async () => {
      if (userData.roles) {
        const token = localStorage.getItem('token');
        const userId = userData?.id;
        const customer = await api.getCustomer(userId, token);
        setGetUserData({ active: customer.data.active, customer_category: customer.data.customer_category.id, email: customer.data.email });
        setPerson(customer.data.person);
        setAddress(customer.data.person.address);
        setPhones(customer.data.person.phones);
        setLoading(false);
      }
      setLoading(false);
    };
    getAdicionalInfo();
  }, []);

  useEffect(() => {
    const getState = async () => {
      const token = localStorage.getItem('token');
      const state = await api.getState(token);
      setStates(state.data);
    };
    getState();
  }, []);

  useEffect(() => {
    if (address.city.state_id) {
      const getCity = async () => {
        const token = localStorage.getItem('token');
        const stateId = "?state_id=" + address.city.state_id;
        const city = await api.getCity(stateId, token);
        setCities(city.data);
        setLoadingCities(false);
      };
      getCity();
    }
  }, [address.state_id]);

  const handleChangeUserData = (e) => {
    const { name, value } = e.target;
    setGetUserData({ ...getUserData, [name]: value });
  };

  const handleChangePerson = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setPerson({ ...person, [name]: value });
  };

  const handleChangeAddress = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleChangePhone = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setPhones({ ...phones, [name]: value });
  };


  let data = {
    user_id: userData?.id,
    active: getUserData?.active,
    customer_category_id: parseInt(getUserData?.customer_category),
    email: getUserData.email,
    person: {
      type: person.type,
      document: person.document,
      name: person.name,
      observations: person.observations,
      site: person.site,
      skype: person.skype,
      rg: person.rg,
      birth_date: person.birth_date,
      fantasy_name: person.fantasy_name,
      state_registration: person.state_registration,
      municipal_registration: person.municipal_registration,
      creation_date: person.creation_date,
      address: {
        city_id: address.city_id,
        state_id: address.state_id,
        postal_code: address.postal_code,
        address_name: address.address_name,
        address_number: address.address_number,
        complement: address.complement,
        neighborhood: address.neighborhood,
      },
      phones: [
        {
          type_phone_id: 1,
          number: phones[0].number,
        },
        {
          type_phone_id: 2,
          number: phones[1].number,
        }
      ],
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    if (data.name === '' || data.email === '' || data.customer_category_id === '' || data.person.document === '' || data.person.address.city_id === '' || data.person.address.state_id === '' || data.person.address.postal_code === '' || data.person.address.address_name === '' || data.person.address.address_number === '' || data.person.phones[0].number === '') {
      toast.error('Preencha todos os campos obrigatórios');
    }
    if (userData.roles.length > 0) {
      const updateUser = api.updateCustomer(userData.id, token, data).then(res => {
        if (res.status === 200) {
          toast.success('Dados atualizados com sucesso!');
        }
      }).catch(err => {
        toast.error('Erro ao atualizar dados, verifique os campos obrigatórios');
      });
      return updateUser;
    } else {
      const createUser = api.createCustomer(token, data).then(res => {
        if (res.status === 200) {
          toast.success('Dados cadastrados com sucesso!');
        }
      }).catch(err => {
        toast.error('Erro ao cadastrar dados, verifique os campos obrigatórios');
      });
      return createUser;
    }
  };

  return (
    <>
      <form
        autoComplete="off"
        noValidate
        {...props}
      >
        <Card>
          <CardHeader
            title="Dados Adicionais"
          />
          <Divider />
          <CardContent>
            {loading ? (<p>Carregando...</p>) : (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-regime-native-simple">Regime</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={person.type || ''}
                      onChange={handleChangePerson}
                      label="Regime"
                      inputProps={{
                        name: 'type',
                        id: 'outlined-regime-native-simple',
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      <option value="F">Fisica</option>
                      <option value="J">Juridica</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                >
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-categoria-native-simple">Categoria</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={parseInt(getUserData.customer_category) || ''}
                      onChange={handleChangeUserData}
                      label="Categoria"
                      inputProps={{
                        name: 'customer_category',
                        id: 'outlined-categoria-native-simple',
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                >
                  <TextField
                    fullWidth
                    helperText="obrigatório CPF ou CNPJ"
                    label="CPF/CNPJ"
                    value={person.document || ''}
                    name="document"
                    onChange={handleChangePerson}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    helperText="obrigatório RG"
                    value={person.rg || ''}
                    label="RG"
                    name="rg"
                    onChange={handleChangePerson}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    value={person.birth_date || ''}
                    label="Data de Nascimento"
                    name="birth_date"
                    onChange={handleChangePerson}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.name || ''}
                    helperText="obrigatório Nome Completo"
                    label="Nome Completo"
                    name="name"
                    onChange={handleChangePerson}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.fantasy_name || ''}
                    label="Razão Social"
                    name="fantasy_name"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.site || ''}
                    label="Site"
                    name="site"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.skype || ''}
                    label="Skype"
                    name="skype"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.state_registration || ''}
                    label="Inscrição Estadual"
                    name="state_registration"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    value={person.municipal_registration || ''}
                    label="Inscrição Municipal"
                    name="municipal_registration"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={5}
                >
                  <TextField
                    fullWidth
                    value={address.address_name || ''}
                    label="Endereço"
                    name="address_name"
                    onChange={handleChangeAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}
                >
                  <TextField
                    fullWidth
                    value={address.address_number || ''}
                    label="Número"
                    name="address_number"
                    onChange={handleChangeAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    value={address.neighborhood || ''}
                    label="Bairro"
                    name="neighborhood"
                    onChange={handleChangeAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    value={address.postal_code || ''}
                    label="CEP"
                    name="postal_code"
                    onChange={handleChangeAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <TextField
                    fullWidth
                    value={address.complement || ''}
                    label="Complemento"
                    name="complement"
                    onChange={handleChangeAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={3}
                >
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-estado-native-simple">Estado</InputLabel>
                    <Select
                      fullWidth
                      required
                      native
                      value={address.city.state_id || ''}
                      onChange={handleChangeAddress}
                      label="Estado"
                      inputProps={{
                        name: 'state_id',
                        id: 'outlined-estado-native-simple',
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>{state.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-cidade-native-simple">Cidade</InputLabel>
                    <Select
                      native
                      required
                      fullWidth
                      value={address.city.id || ''}
                      onChange={handleChangeAddress}
                      label="Cidade"
                      inputProps={{
                        name: 'city_id',
                        id: 'outlined-cidade-native-simple',
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      {loadingCities ? <option value="">Carregando...</option> :
                        cities.map((cityList) => (
                          <option key={cityList.id} value={cityList.id}>{cityList.name}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    value={phones[0].number || ''}
                    label="Telefone"
                    name="phone_number"
                    onChange={handleChangePhone}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                >
                  <TextField
                    fullWidth
                    label="Celular"
                    name="phone_number"
                    value={phones[1].number || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={4}
                >
                  <TextField
                    fullWidth
                    value={getUserData.email || ''}
                    label="E-mail"
                    name="email"
                    onChange={handleChangeUserData}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    value={person.observations || ''}
                    label="Observação"
                    name="observations"
                    onChange={handleChangePerson}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              Salvar
            </Button>
          </Box>
        </Card>
      </form>
      <ToastContainer />
    </>
  );
};

export default AccountProfileFullDetails;

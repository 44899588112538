/* eslint-disable */
/* eslint-disable eol-last */
import React, { useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useApi } from '../../hooks/useApi';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const api = useApi();

  const signUp = async (firstName, lastName, email, password) => {
    const res = await api.signUp(firstName, lastName, email, password);
    return res;
  };

  const signin = async (email, password) => {
    const data = await api.signIn(email, password);

    if (data.access_token) {
      setToken(data.access_token);
      return true;
    }
    return false;
  };

  const setToken = (access_token) => {
    localStorage.setItem('token', access_token);
  };

  useEffect(() => {
    const dataUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const data = await api.user(token);
        localStorage.setItem('user', JSON.stringify(data.data));
        localStorage.setItem('userId', JSON.stringify(data.data.id));
        localStorage.setItem('avatar', data.data.image_url);
        setUser(data);
      }
    };
    dataUser();
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const data = await api.checkToken(token);
        if (data.access_token) {
          setToken(data.access_token);
        } else {
          localStorage.removeItem('token');
          window.location.href = '/';
        }
      }
    };
    validateToken();
  }, []);

  const signout = async () => {
    const token = localStorage.getItem('token');
    await api.logout(token);
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ user, signin, signUp, signout }}>
      {children}
    </AuthContext.Provider>
  );
}
/* eslint-disable prefer-const */
/* eslint-disable */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { useApi } from '../hooks/useApi';

// eslint-disable-next-line no-unused-vars
const RegisterForAdmin = () => {
  const api = useApi();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      if (email && password && name && lastName) {
        const res = await api.signUpForAdmin(token, name, lastName, email, password);
        if (res.success) {
          toast.success('Cadastro realizado com sucesso!');
        }
      } else {
        toast.warn('Preencha todos os campos');
      }
    } catch (err) {
      if (err === 'Unauthorized') {
        toast.warn('Essa conta já está cadastrada');
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm" style={{ backgroundColor: "#fff" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nome"
            margin="normal"
            name="name"
            variant="outlined"
            value={name}
            onChange={(t) => setName(t.target.value)}
          />
          <TextField
            fullWidth
            label="Sobrenome"
            margin="normal"
            name="lastName"
            variant="outlined"
            value={lastName}
            onChange={(t) => setLastName(t.target.value)}
          />
          <TextField
            fullWidth
            label="Email Address"
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(t) => setEmail(t.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(t) => setPassword(t.target.value)}
          />
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Criar
            </Button>
          </Box>
        </form>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default RegisterForAdmin;
/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet';

import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';

const EmailValidation = () => {
  return (
    <>
      <Helmet>
        <title>Error | Helfer Comunicação</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm" style={{ backgroundColor: "#fff" }}>

          <Box sx={{ mb: 3 }}>
            <Typography
              color="red"
              variant="h2"
              sx={{ mb: 3 }}
            >
              Error!
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Erro encontrado ao tentar acessar essa pagina.
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
            </Typography>
          </Box>
        </Container>

      </Box>
    </>
  );
}

export default EmailValidation;
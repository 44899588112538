/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  DialogActions
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Plus as AddIcon
} from 'react-feather';
import RegisterForAdmin from '../../pages/RegisterForAdmin';
import GlobalDialogs from '../Dialog/GlobalDialogs';

const UsersListToolbar = (props) => {

  const [open, setOpen] = useState(false);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '1rem'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <SvgIcon
            fontSize="small"
            color="white"
          >
            <AddIcon />
          </SvgIcon>
          Usuário
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Pesquisar Usuário"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <GlobalDialogs title={"Criar Usuário"} openDialog={open} setOpenDialog={setOpen}>
        <RegisterForAdmin />
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </GlobalDialogs>
    </Box>
  )
};
export default UsersListToolbar;

import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import MakeOrders from './pages/MakeOrders';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import OrderList from './pages/OrderList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import MyPlans from './pages/Sale/MyPlans';
import Plans from './pages/Sale/Plans';
import UsersList from './pages/Users/ListUsers';
import EmailValidation from './pages/EmailValidation';
import EmailValidationInvalid from './pages/EmailValidationInvalid';
import Errors from './pages/Errors';

function PrivateRoute() {
  const auth = localStorage.getItem('token');
  return auth ? <DashboardLayout /> : <Navigate to="/" />;
}

function PublicRoute() {
  const authUser = localStorage.getItem('token');
  return authUser ? <Navigate to="/app/dashboard" /> : <MainLayout />;
}

const routes = [
  {
    path: '/',
    element: <PublicRoute />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: 'validation', element: <EmailValidation /> },
      { path: 'invalid', element: <EmailValidationInvalid /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'error', element: <Errors /> }
    ]
  },
  {
    path: 'app',
    element: <PrivateRoute />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'order', element: <OrderList /> },
      { path: 'order/new', element: <MakeOrders /> },
      { path: 'myplans', element: <MyPlans /> },
      { path: 'settings', element: <Settings /> },
      { path: 'users', element: <UsersList /> },
      { path: 'plans', element: <Plans /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

/* eslint-disable prefer-const */
/* eslint-disable no-alert */
import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { AuthContext } from 'src/contexts/Auth/AuthContext';

const Login = () => {
  const auth = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (email !== '' && password !== '') {
        const data = await auth.signin(email, password);
        if (data) {
          toast.success('Login realizado com sucesso!');
          window.location.href = '/app/dashboard';
        }
      } else {
        toast.warn('Preencha todos os campos');
      }
    } catch (err) {
      if (err.response.status === 401) {
        toast.warn('Email ou senha incorretos');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Helfer Comunicação</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={handleLogin}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Login
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Acesse sua plataforma
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              type="email"
              value={email}
              variant="outlined"
              onChange={(t) => setEmail(t.target.value)}
            />
            <TextField
              fullWidth
              label="Senha"
              margin="normal"
              name="password"
              type="password"
              value={password}
              onChange={(t) => setPassword(t.target.value)}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Entrar
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Você não tem uma conta?
              {' '}
              <Link component={RouterLink} to="/register" variant="h6" underline="hover">
                Clique aqui
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
      <ToastContainer />
    </>
  );
};

export default Login;

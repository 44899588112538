/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable */
import { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from 'src/contexts/Auth/AuthContext';
import { useApi } from '../../hooks/useApi';

const AccountProfileDetails = (props) => {
  const { user } = useContext(AuthContext);
  const userData = user?.data;
  const api = useApi();
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  useEffect(() => {
    const getUser = async () => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const resp = await api.getUser(userId, token);
      setValues(resp.data);
    };
    getUser();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userId = userData.id;
    const token = localStorage.getItem('token');
    const updateUser = api.updateUser(userId, token, values);
    toast.success('Dados atualizados com sucesso!');
    return updateUser;
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Digite seu nome"
                label="Nome"
                name="first_name"
                onChange={handleChange}
                required
                value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Sobrenome"
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                required
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Box>
      </Card>
      <ToastContainer />
    </form>
  );
};

export default AccountProfileDetails;

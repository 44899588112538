/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable jsx-quotes */
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  TextField,
  Box,
  Button,
  Switch,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TableBody,
  IconButton,
  Chip,
  TablePagination
} from '@material-ui/core';
import { useApi } from 'src/hooks/useApi';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


const CategoryPlans = (props) => {
  const api = useApi();
  const [categoryName, setCategoryName] = React.useState('');
  const [categoryDescription, setCategoryDescription] = React.useState('');
  const [categoryList, setCategoryList] = React.useState({});
  const [checked, setChecked] = React.useState({ checked: true });
  const [loading, setLoading] = useState(true);

  const handleChangeChecked = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    const data = {
      name: categoryName,
      description: categoryDescription,
    };
    const createCategory = api.createCategoryPlan(token, data).then((resp) => {
      toast.success('Categoria criada com sucesso!');
      setCategoryName('');
      setCategoryDescription('');
      setChecked({ checked: true });
    }
    ).catch((err) => {
      toast.error('Erro ao criar categoria!');
    });
    return createCategory;
  };

  useEffect(() => {
    const getCategory = async () => {
      const token = localStorage.getItem('token');
      const resp = await api.listCategoryPlan(token);
      setCategoryList(resp.data);
      setLoading(false);
    };
    getCategory();
  }, []);

  return (
    <>
      {!loading ? (
        <form
          autoComplete="off"
          noValidate
          {...props}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Categoria"
                name="categoryName"
                onChange={(e) => setCategoryName(e.target.value)}
                required
                value={categoryName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                onChange={(e) => setCategoryDescription(e.target.value)}
                required
                value={categoryDescription || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={1}
              xs={3}
            >
              <Typography>
                Ativo
              </Typography>
              <Switch
                checked={checked.checked}
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              mt={1}
            >
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} mt={2}>
            <Typography>
              Lista de categorias
            </Typography>
            <Divider />
            <Grid>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Código
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Nome da categoria
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell align='center'>
                      Editar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryList.map((category) => (
                    <TableRow
                      hover
                      key={category.id}
                    >
                      <TableCell>
                        {category.id}
                      </TableCell>
                      <TableCell>
                        {category.name}
                      </TableCell>
                      <TableCell>
                        {category.active === true ? <Chip label="Ativo" color="primary" /> : <Chip label="Inativo" color="secondary" />}
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton aria-label="edit" color="successColor">
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" color="warningColor">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <ToastContainer />
        </form >)
        : (<Typography>Carregando...</Typography>)}
    </>
  );
}

export default CategoryPlans;

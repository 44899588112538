/* eslint-disable */
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api-sandbox.helfercomunicacao.com.br/api',
});

export const useApi = () => ({

  // Rotas de login e cadastro
  checkToken: async (token) => {
    const response = await api.post('/auth/refresh', '', { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  },
  signIn: async (email, password) => {
    const response = await api.post('/auth/login', { email, password });
    return response.data;
  },
  signUp: async (firstName, lastName, email, password) => {
    const response = await api.post('/users/create/without-auth',
      {
        first_name: firstName,
        last_name: lastName,
        image: null,
        email: email,
        password: password
      });
    return response.data;
  },
  signUpForAdmin: async (token, firstName, lastName, email, password) => {
    const response = await api.post('/users',
      {
        first_name: firstName,
        last_name: lastName,
        image: null,
        email: email,
        password: password
      }, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  },
  logout: async (token) => {
    const response = await api.post('/auth/logout', { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  },
  // fim das rotas de login e cadastro

  // Rotas de usuários
  user: async (token) => {
    const response = await api.get('/auth/user', { headers: { Authorization: `Bearer ${token}` } });
    if (!response.data) {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      return response.data;
    }
  },
  userList: async (token) => {
    const response = await api.get('/users', { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  },
  getUser: async (id, token) => {
    const response = await api.get(`/users/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    if (!response.data || response.data === 'undefined') {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else {
      return response.data
    }
  },
  updateUser: async (id, token, data) => {
    const response = await api.put(`/users/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // fim das rotas de usuários

  // Rotas de Categoria de planos
  createCategoryPlan: async (token, data) => {
    const response = await api.post('/plans/categories', data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  updateCategoryPlan: async (token, name, description) => {
    const response = await api.put('/plans/categories', { name, description }, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  listCategoryPlan: async (token) => {
    const response = await api.get('/plans/categories', { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  deleteCategoryPlan: async (token, id) => {
    const response = await api.delete(`/plans/categories/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // fim das rotas de categoria de planos

  // Rotas de planos
  createPlan: async (token, data) => {
    const response = await api.post('/plans', data, { headers: { Authorization: `Bearer ${token}` } });
    return response
  },
  updatePlan: async (id, token, data) => {
    const response = await api.post(`/plans/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  deletePlan: async (id, token) => {
    const response = await api.post(`/plans/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  getPlans: async (token) => {
    const response = await api.get('/plans', { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // Fim rota de planos

  // Rota de regras de usuario
  getRoles: async (token) => {
    const response = await api.get(`/type-users`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // Fim rota de regras de usuario

  // Rota de clientes
  createCustomer: async (token, data) => {
    const response = await api.post('/customers', data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  updateCustomer: async (id, token, data) => {
    const response = await api.put(`/customers/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  getCustomer: async (id, token) => {
    const response = await api.get(`/customers/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  customerCategory: async (token) => {
    const response = await api.get(`/customers/categories`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  getState: async (token) => {
    const response = await api.get(`/states`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  getCity: async (id, token) => {
    const response = await api.get(`/cities${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // Fim rota de clientes

  // Rota de vendas
  salesPlan: async (token, data) => {
    const response = await api.post(`/customers/plans`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data
  },
  // Fim rota de vendas

  // Rota de Anunciantes 
  getAnnouncers: async (token) => {
    const response = await api.get(`/announcers`, { headers: { Authorization: `Bearer ${token}` } });
    console.log('retorno anunciantes', response.data)
    return response.data
  }
  // fim de rota anunciantes
})